import React, { Dispatch, FC, MouseEvent } from "react";
import { StaticImage } from "gatsby-plugin-image";

import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";

type ContactModalProps = {
	active: boolean;
	setActive: Dispatch<React.SetStateAction<boolean>>;
};

const ContactModal: FC<ContactModalProps> = ({ active, setActive }) => {
	const closeModal = (event: MouseEvent<HTMLElement>) => {
		const target = event.target as Element;
		if (target.classList.contains("contact-modal")) {
			setActive(false);
		}
	};

	return (
		<div className={`contact-modal ${active ? "contact-modal--visible" : ""}`} onClick={closeModal}>
			<div className={`contact-modal__content ${active ? "contact-modal__content--visivle" : ""}`}>
				<div className="contact-modal__header">
					<StaticImage
						className="contact-modal__img"
						src="../../assets/img/alex.webp"
						alt="Alex Blokh"
						placeholder="blurred"
						imgStyle={{ borderRadius: "50%" }}
					/>
					<div className="contact-modal__info">
						<p className="contact-modal__cto">Alex Blokh</p>
						<p className="contact-modal__text">
							Co-founder and CTO of{" "}
							<a href="https://lambda.direct/" target="_blank" className="contact-modal__link">
								lambda.direct
							</a>
						</p>
					</div>
				</div>
				<div className="contact-modal__chat">
					<div className="contact-modal__message">Send me an email and I will arrange everything</div>
					<div className="contact-modal__smile">😍 1</div>
				</div>
				<ClipboardCopy text="alex.blokh@lambda.direct" />
				<a href="mailto:alex.blokh@lambda.direct" target="_blank" className="contact-modal__btn">
					Send email
				</a>
			</div>
		</div>
	);
};

export default ContactModal;
