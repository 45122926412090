import React, { FC, useState } from "react";

type ClipboardCopyProps = {
	size?: number;
	color?: string;
	text: string;
};

const ClipboardCopy: FC<ClipboardCopyProps> = ({ size = 24, color = "#5b5b5b", text }) => {
	const [copied, setCopied] = useState(false);

	const clipboardHandler = () => {
		if (navigator?.clipboard?.writeText) {
			navigator.clipboard.writeText(text);
		} else {
			const el = document.createElement("textarea");
			el.value = text;
			el.setAttribute("readonly", "");
			el.style.position = "absolute";
			el.style.left = "-9999px";
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		}

		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 1500);
	};

	return (
		<div className="clipboard-copy">
			{text}
			<button
				type="button"
				className={`clipboard-copy__btn ${copied ? "clipboard-copy__btn--active" : ""}`}
				onClick={clipboardHandler}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width={size}
					height={size}
					viewBox="0 0 24 24"
					fill="none"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
					<path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
				</svg>
			</button>
		</div>
	);
};

export default ClipboardCopy;
